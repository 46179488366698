// import 'bootstrap'

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faDesktop,
  faLock,
  faClock,
  faDraftingCompass,
  faAngleRight,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faEdit, faPaperPlane } from '@fortawesome/free-regular-svg-icons';

library.add(
  faDesktop,
  faLock,
  faClock,
  faDraftingCompass,
  faAngleRight,
  faPhone,
  faEnvelope
);
library.add(faEdit, faPaperPlane);
dom.watch();

import '../scss/index.scss';
